:root {
    --rowNum: 5;
    --colNum: 8;
    --viewHeight: 100px;
    --cell-border-radius: 12px;
    --label-transform: "";
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.container-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 70px;
}

.container-grid-onsite {
    grid-template-rows: auto 80px 70px !important;
}

.cell-hover-view {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    bottom: 0;
    border-radius: var(--cell-border-radius);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.cell-hover-view > p {
    margin: 0;
    padding: 1rem;
    text-align: center;
    font-size: 20px;
}

.cell-hover-view:hover > p {
    opacity: 1;
}

.cell-hover-view > svg {
    /* height: 90px; */
}

.offsite-cell-hover-view:hover {
    opacity: 1;
    background: rgba(46, 16, 23, 0.7);
}

.onsite-cell-hover-view:hover {
    opacity: 1;
    background: rgba(99, 105, 209, 0.7);
}

.offsite-cell-hover-view > p {
    color: white;
}

.onsite-cell-hover-view > p {
    color: white;
}

.labels-content {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 150;
    user-select: none;

    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}

.cell-name-label {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.7rem 0.2rem 0.7rem;
    transform: var(--label-transform);
    background-color: #2B2B2B;

    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.cell-name-label > p {
    margin: 0;
    color: white;
}

.type-label-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    transform: var(--label-transform);
    z-index: 150;
    padding: 0.3rem 0.7rem 0.2rem 0.7rem;

    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.type-label-container > img {
    width: 20px;
    margin-right: 0.5rem;
}

.type-label-container > p {
    margin: 0;
    text-align: center;
    color: white;
    /* font-size: 14px; */
}

.onsite-label {
    background-color: #6369D1;
    color: white;
}

.no-border-radius {
    border-radius: 0 !important;
}

.offsite-label { background-color: var(--daniyal-pink); }
.offsite-label > p { color: #000; }

.type-choosing-container {
    width: 100%;
    background-color: var(--dark-green);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.hero-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0rem 1rem 1rem 1rem;
}

.hero_text {
    text-align: center;
    font-size: 35px;
    color: var(--betty-white);
    font-weight: 500;
}


.type-choosing-view-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.type-choosing-view-mobile > .type-choosing-button:first-child {
    margin-bottom: 2rem;
}

.type-choosing-view-mobile > .type-choosing-button {
    width: 90%;
}

.type-choosing-view-mobile > .type-choosing-button > .type-choosing-text-container > .badge-holder {
    top: calc(1rem + 0.6rem);
    right: 12px;
}

.type-choosing-view {
    /* flex: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    /* overflow visible for scale animation */
    width: 85%;
    /* background-color: red; */
    overflow: visible; 
}

.type-choosing-view > .type-choosing-button:first-child {
    margin-right: 2rem;
}

.type-choosing-button {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;

    -webkit-transition: 0.15s ease-in-out;
    -moz-transition: 0.15s ease-in-out;
    -o-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
}

.type-choosing-button:hover {
    scale: 1.05;
}

.type-choosing-text-container {
    padding: 1rem;
    flex: 1;
    width: 100%;
    position: relative;
}

.type-choosing-text-container > h5, p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.offsite-choosing { background-color: var(--daniyal-pink); }
.offsite-choosing > .type-choosing-text-container { color: black; }

.onsite-choosing { background-color: var(--hulk-purple); }
.onsite-choosing > .type-choosing-text-container { color: var(--betty-white); }

.divider {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #f4f2f7;
}

.error-message {
    max-width: 50%;
    color: #f4f2f7 !important;
}

.screen-share-view {
    height: calc(100vh - 70px);
    width: 100%;
    position: absolute;
    z-index: 260;
    background-color: var(--dark-green);
    scrollbar-width: none;
    display: flex;
    justify-content: center;
}

.screen-share-view video {
    object-fit: contain;
}

.cell-loading-animation {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 200;
    bottom: 0;
    border-radius: var(--cell-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.main-mic-border {
    border: 0 #6369D1 solid;
    border-radius: calc(var(--cell-border-radius) + 2px);
}

.scale-anim {
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.dev_btn {
    position: absolute;
    top: 0;
    left: 0;
}


/* new video grid css */

.room-main-view {
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.Dish {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; /* 100% of the viewport height */
    overflow: scroll;
}

/* Camera */
.Dish >.video-cell {
    border-radius: 10px;
    overflow: hidden;
    margin: 5px;
    box-sizing: border-box;
    position: relative;
}

/* Video (check the nice property object-fit) */
.Dish > .video-cell > .video-content {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: black;
}

.Dish > .video-cell > .video-content > video {
    width: 100%;
    height: 100%;
    background-color: black;
    border-radius: 10px;
    
    object-fit: cover;
    background-size: cover;
}

.small-media-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    
    background-color: var(--betty-white);
    border-radius: 50%;

}

.small-media-icon > .media-state-icons > img {
    margin-right: 0;
    width: 25px;
    margin: 8px;
}
.media-state-icons {
    flex-flow: row;
    display: flex;
}

.media-state-icons > img {
    margin-right: 10px;
    width: 20px;
}

.onsite-locations {
    display: flex;
    flex-wrap: nowrap;
    /* flex-flow: row; */
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
}

.onsite-locations > .onsite-location-container:not(:last-child) {
    margin-right: 3rem;
}

.onsite-location-container {
    background-color: #081617;
    border-radius: 0.5rem;
    padding: 1.5rem;
    height: 360px;
    width: 280px;
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    align-items: flex-start;
}

.onsite-location-container > h5 {
    font-size: 25px;
    color: #FFF;
}

.onsite-location-container > p {
    color: #ECECEC;
    opacity: 0.7;
    padding: 0;
    margin: 0;
}

.onsite-location-container > .onsite-people-list {
    display: flex;
    flex-flow: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex: 1;
}

.onsite-location-container > .onsite-people-list > .onsite-person:not(:last-child) {
    margin-bottom: 1rem;
}

.onsite-person {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}

.onsite-person > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 1rem;
}

.onsite-person > p {
    margin: 0;
    font-size: 15px;
    color: #FFF;
}

.more-user > p { color: #A1B1B3; }

.onsite-location-container > .location-join-btn {
    color: #102C2E;
    font-weight: 500;
    font-size: 18px;
    bottom: 0;

    background-color: #EAE8E1;
    padding: 0.5rem 0rem;
    border: 0;
    border-radius: 0.5rem;
    width: 100%;
}

/* create location button */

.create-location > h5 {
    font-size: 20px;
    text-align: center;
    flex: 1;
}

.create-location > .location-join-btn {
    color: #102C2E;
    background-color: #9AC2C5;
}

.perm-detail-container > h3 {
    text-align: center;
    margin-top: 1rem;
    font-size: 20px;
    color: #102C2E;
}

.perm-detail-container > p {
    margin: 0;
    text-align: center;
    color: #102C2E;
}

.perm-detail-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

/* specific icon */
/* .perm-detail-container > .perm-image {
    width: 80px;
    border-radius: 0;
    margin-bottom: 1rem;
} */

/* big image */
.perm-image {
    width: 400px;
    border-radius: 5px;
}

.welcome-detail-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.welcome-detail-container > h3 {
    text-align: center;
    margin-top: 1rem;
    font-size: 20px;
    color: #102C2E;
}

.welcome-detail-container > p {
    text-align: center;
    color: #102C2E;
    margin: 0;
}
  
.modal-container > .action-container {
  margin-top: 1rem;
}

.between-toast-body {
    font-family: "AktivGrotesk";
}

.badge-holder {
    position: absolute;

    /* 1rem as base for parent padding */
    top: calc(1rem + 0.6rem);
    right: calc(1rem + 3rem);
}

.hybrid-badge {
    padding: 0.4rem 0.5rem 0.3rem 0.5rem;
    border-radius: 5px;
    background-color: var(--betty-white);
    color: var(--hulk-purple);
    font-weight: 500;
    font-size: 14px;
    user-select: none;
    font-weight: 700;
    margin-left: 1rem;
}

.browser-modal-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.browser-modal-content > img {
    width: 100px;
}

.browser-modal-content > h4 {
    font-weight: 400;
}


.connection-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.connection-badge > p {
    padding: 0;
    margin: 0;
    color: var(--betty-white);
}

.connection-hover {
    position: absolute;
    top: -80px;
    right: 0;
    background-color: #040808;
    width: 400px;
    border-radius: 0.5rem;
    user-select: none;
}

.connection-hover > p {
    text-align: center;
    margin: 0;
    padding: 0.7rem 0.5rem 0.5rem 0.5rem;
    color: white;
}

.connection-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
}

.connection-circle-good {
    background-color: var(--bright-green);
}

.connection-circle-bad {
    background-color: var(--red-dit);
}

.onsite-thumb-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    position: relative;
    margin: 5px;
    border-radius: 10px;

    grid-row-start: 2;
    grid-row-end: auto;

    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.onsite-container-active {
    background-color: var(--dark-overlay);
}

.thumb-holder {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    transform: scale(1);

    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.thumb-holder-inactive {
    transform: scale(0.7);
    opacity: 0.2;
}

.onsite-info {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}

.onsite-info > img {
    width: 25px;
    cursor: pointer;
}

.onsite-header {
    position: absolute;
    right: 30px;
    bottom: 40px;
    width: 300px;
    z-index: 200;
    padding: 0.5rem;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    border-radius: 10px;
    background-color: var(--black);
    
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.onsite-header > h5 {
    color: var(--betty-white);
    margin: 0;
}

.onsite-header > p {
    color: var(--betty-white);
    margin: 0;
}

.onsite-thumb {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 0.5rem;

    cursor: pointer;
}

.onsite-thumb > img {
    border-radius: 50%;
    width: 100%;
    height: 100%;

    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.onsite-thumb > img:hover {
    transform: scale(1.1);    
}

.thumb-hover {
    position: absolute;
    top: -60px;
    right: 0;
    background-color: #040808;
    border-radius: 0.5rem;
    user-select: none;
    opacity: 1;

    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}


.thumb-hover-active {
    animation: fadein 0.2s;
}

.thumb-hover-inactive {
    animation: fadeout 0.2s;
}

.thumb-hover > p {
    margin: 0;
    color: white;
    padding: 0.7rem 0.5rem 0.5rem 0.5rem;
}

.test-video-view {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.debugView {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.debugView > p {
    margin: 0;
    padding: 0;
    color: white;
}

.centered {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.device-selection-base {
    width: 30%;
    max-width: 400px;
    display: flex;
    flex-flow: column;
    background-color: var(--platinum);
    border-radius: 10px;
}

.device-selection-base {
    padding: 0.5rem;
}

.device-selection-base > video {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.device-list-view {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    
    padding: 2rem;
    flex: 1;
}

.device-list-view > p {
    text-align: center;
}

.select-view {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.75rem;
}

.select-view > img {
    height: 25px;
}

.device-list {
    background-color: white;
    padding: 0.5rem;
    width: 100%;
    border: 1px solid var(--dark-green);
}

.mobile-width {
    width: 90% !important;
}

.cell-loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.auto-location-view {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.auto-location-view > p { color: var(--betty-white); }
.auto-location-view > h5 { color: var(--betty-white); }

.auto-location-view > .underline-btn {
    position: absolute;
    top: calc(100vh - 140px);
    /* left: 0; */
}

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    z-index: 2000;
    position: fixed;
    height: 90px;
    width: 90px;
    margin: auto;
    top: -300px;
    left: 0;
    right: 0;
    bottom: 0;
}

.pace.pace-inactive .pace-progress {
    display: none;
}

.pace .pace-progress {
    position: fixed;
    z-index: 2000;
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 90px;
    width: 90px !important;

    display: block;
    border-width: 30px;
    border-radius: 50%;

    border-style: double;
    border-color: var(--opal) transparent transparent;

    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    -o-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.pace .pace-progress:before {
    content: ' ';
    position: absolute;
    top: 7px;
    left: 7px;
    /* height: 50px; */
    /* width: 50px !important; */
    display: block;
    border-width: 10px;
    border-radius: 50%;

    border-style: solid;
    border-color: var(--opal) transparent transparent;

}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(359deg);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(359deg);
    }
}

@-o-keyframes spin {
    100% {
        -moz-transform: rotate(359deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(359deg);
    }
}

.muted-bar {
    position: absolute;
    bottom: 100px;
    background-color: var(--opal);
    padding: 1.8rem 5rem 1rem 5rem;
    border-radius: 10px;
    width: 600px;
    left: calc((100vw / 2) - 300px);
    user-select: none;
    z-index: 1000;
}

.muted-hover-active {
    opacity: 1;
    animation: fadein 0.2s;
}

.muted-bar > h2 {
    text-align: center;
}

.delayDiv {
    background-color: red;
    width: 200px;
}

.delayDiv > p {
    margin: 0;
    /* background-color: blue; */
}

#manualDelayInput {
    width: 90px;
    margin-right: 10px;
}

#delayValueLabel {
    color: var(--betty-white);
}