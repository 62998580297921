:root {
    --red-dit: #dc3545;
    --dark-red: #e23e34;
    --see-blue: #3471db;
    --betty-white: #ffffff;
    --background: #f0f0f0;
    --background-accent: #ddd9f2;
    --washed-text: #b2b2b2;
    --dark-overlay: rgba(0, 0, 0, 0.2);
    --regular-text: #464646;
    --bright-green: #D9FF00;
    --dark-green: #102C2E;
    --opal: #9AC2C5;
    --black: #000000;
    --hulk-purple: #6369D1;
    --platinum: #e6eaeb;
    --daniyal-pink: #CEA2AC;
}

@font-face {
    font-family: "AktivGrotesk";
    src: local("Aktiv-Grotesk-Light"),
    url("../fonts/AktivGrotesk-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "AktivGrotesk";
    src: local("Aktiv-Grotesk"),
    url("../fonts/AktivGrotesk-Regular.ttf") format("truetype");
    font-weight: 400;
}
  
@font-face {
    font-family: "AktivGrotesk";
    src: local("Aktiv-Grotesk-Medium"),
    url("../fonts/AktivGrotesk-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "AktivGrotesk";
    src: local("Aktiv-Grotesk-Bold"),
    url("../fonts/AktivGrotesk-Bold.ttf") format("truetype");
    font-weight: 600;
}

@keyframes slidein {
    from {
        right: -28rem;
    }
    to {
        right: 0rem;
    }
}

body {
    background-color: var(--betty-white);
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p, input{
    font-family: "AktivGrotesk";
}

::-webkit-scrollbar {
    display: none;
}

.fill-window {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.page-content {
    flex: 1 0 auto;
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.w-40 {
    width: 40%;
}

/* Boxes and Inputs */

.red-team {
    color: var(--red-dit);
}

.blue-team {
    color: var(--see-blue);
}

.hidden-text {
    color: var(--background);
}

.dark-bg {
    background-color: var(--regular-text);
}

.regular-bg {
    background-color: var(--background);
}

.accent-0-bg {
    background-color: var(--background-accent);
}

.accent-1-bg {
    background-color: var(--accent-1);
}

.accent-2-bg {
    background-color: var(--accent-2);
}

.accent-3-bg {
    background-color: var(--accent-3);
}

.washed-out-bg {
    background-color: var(--washed-text);
}

.cool-box-bg-yellow {
    background-color: #ffc107;
}

.cool-box-bg-secondary {
    background-color: var(--betty-white) !important;
}

.cool-box-bg-dark {
    background-color: var(--betty-white) !important;
}

.cool-box-radius {
    border-radius: 1rem !important;
}

.cool-box-shadow {
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

.main-bg {
    background-attachment: fixed;
}

.main-wrapper {
    height: 100vh;
    display: flex;
    flex-flow: column;
    position: relative;
    background-color: var(--dark-green);
}

.main-wrapper-login-page {
    height: 100vh;
    display: flex;
    flex-flow: column;
    background: url(../login-bg.png);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    scrollbar-width: none;
}

.main-wrapper-landing-page {
    height: 100vh;
    display: flex;
    flex-flow: column;
    background: url(../landing-bg.png);
    background-repeat: no-repeat;
    scrollbar-width: none;
}

.main-wrapper-room-page {
    height: 100vh;
    display: flex;
    flex-flow: column;
}

.nav-bar-shadow {
    -webkit-box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--betty-white);
}

.venue-holder {
    padding: 15px;
    background-color: var(--betty-white);
    border-radius: 8px;
    margin-bottom: 15px;
    cursor: pointer;

    -webkit-box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);

    -webkit-transition: 0.12s ease-in-out;
    -moz-transition: 0.12s ease-in-out;
    -o-transition: 0.12s ease-in-out;
    transition: 0.12s ease-in-out;
}

.person-container {
    flex: 2 1;
    overflow-x: auto;
    width: 100%;
    display: flex;
}

.person-holder {
    width: 60px;
}

.person {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: #7f8fa6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.person-xs {
    height: 25px;
    width: 25px;
    border-radius: 0.25rem;
    background-color: #7f8fa6;
}

.login-button {
    background-color: var(--betty-white);
    border-radius: 8px;
    border: 0;
    padding: 10px;
    color: var(--regular-text);
    font-size: 17px;
    display: flex;
    align-items: center;
}

.login-button:hover {
    background-color: var(--opal);
}

.login-button div {
    margin-right: 10px;
    /* height: 22px; */
    /* width: 22px; */
}


.f-1 { flex: 1; }
.f-2 { flex: 2; }
.f-3 { flex: 3; }
.f-4 { flex: 4; }
.f-5 { flex: 5; }
.f-6 { flex: 6; }

/* Text */

.light-text { color: var(--betty-white); }
.dark-text { color: #000000; }
.accent-text { color: var(--bright-green); }
.washed-out { color: gray; }

.scroll-view-fixed {
    flex: 2;
    overflow: scroll;
}

.red-btn { background: #e23e34; }
.red-btn > p { color: #FFFFFF; }

.green-btn { background: var(--bright-green) }
.green-btn > p { color: #000000 }

.opal-btn { background: var(--opal) }
.opal-btn > p { color: #000000 }

.secondary-btn { background-color: var(--washed-text); }
.secondary-btn > p { color: #000000; }

.purple-btn { background-color: #6369D1; }
.purple-btn > p { color: #FFFFFF; }

.underline-btn {
    background-color: transparent;
}
.underline-btn > p {
    color: var(--bright-green);
    text-decoration: underline;
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.o-100 {
    opacity: 1;
}

.o-50 {
    opacity: 0.5;
}

.hover-btn {
    -webkit-transition: 0.12s ease-in-out;
    -moz-transition: 0.12s ease-in-out;
    -o-transition: 0.12s ease-in-out;
    transition: 0.12s ease-in-out;
}

.hover-btn:hover {
    transform: scale(1.05, 1.05);

    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.modal-content {
    border-radius: 1rem !important;
}

.pointer {
    cursor: pointer;
}

.abs-side-view {
    position: absolute;
    bottom: 70px;
    right: 0rem;
    width: 28rem;
    height: calc(100% - 70px);
    background: var(--platinum);
    overflow: hidden;
    z-index: 300;
    display: flex;
    flex-flow: column;

    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
}

.slide-in {
    animation: slidein 0.2s;
}

.slide-out {
    animation: slidein 0.2s reverse;
}

.hide-side {
    opacity: 0;
    /* right: -28rem; */
    z-index: -100;
}

.abs-dropdown-view {
    position: absolute;
    width: 12rem;
    height: 10rem;
    background: var(--black);
    border-radius: 10px;
    overflow-y: scroll;
    z-index: 300;
    display: flex;
    flex-flow: column;

    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
}

.abs-audio-input-dropdown {
    bottom: 80px;
    right: calc(50% + 8.3rem);
}

.abs-video-input-dropdown {
    bottom: 80px;
    right: calc(50% + 1.3rem);
}

.abs-audio-output-dropdown {
    bottom: 80px;
    right: calc(50% - 6rem + 10rem);
}

.hide-dropdown {
    opacity: 0;
    z-index: -100;
}

.i-am-strong { font-weight: 600; }

.base-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.55rem 1rem 0.5rem 1rem;
    font-weight: 500;
    border-radius: 5px;
    border: 0;
    user-select: none;
}

.base-button:hover {
    cursor: pointer;
    opacity: 0.8;
}
  
.base-button > p {
    margin: 0;
    padding: 0.1rem 0 0 0;
}

.base-input {
    color: white;
    background-color: #263E40;
    border: 0px;
    resize: none;
    width: 100%;
    font-size: 15px;
    overflow: hidden;
    padding: 0.85rem 0.75rem 0.75rem 0.75rem;
    border-radius: 5px;
}

.guest-input-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.guest-input-container > p { 
    margin: 0;
    padding: 0;
    user-select: none;
 }

.modal-container > .action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

