.dropdown-container {
    border-radius: 10px;
}

.dropdown-item {
    color: #e0deed;
    padding: 0;
    margin: 0;
}

.dropdown-item:focus {
    background-color: transparent;
    color: #e0deed;
}

.dropdown-item > div {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    font-size: 14px;
    padding: 0.6rem 0.9rem;
}

.dropdown-item > div > p {
    font-size: 14px;
    color: var(--betty-white);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-audio-toggle {
    /* background-color: red; */
    margin-bottom: 0.5rem;
    border-bottom: #e0deed 0.5px solid;
}

.dropdown-audio-toggle:hover {
    background-color: black !important;
}

.dropdown-item:hover {
    background-color: var(--dark-green);
    color: #e0deed;
}

.dropdown-item img {
    width: 20px;
    height: 20px;
}