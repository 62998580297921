.loading-animation {
    height: 100%;
    width: 100%;
    scrollbar-width: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pos-abs { position: absolute; }

.animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    /* padding: 2rem 4rem 2rem 4rem; */
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.animation-container-video {
    background: var(--dark-green);
}

.animation-container-small {
    background: transparent;
}

.loading-spinner {
    height: 50%;
}

.loading-spinner-video {
    height: 20%
}
