.onboarding-header-container {
    display: flex;
    flex-flow: row;
    padding-bottom: 3rem;
}

.onboarding-header {
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 2.5rem;
    color: var(--betty-white);
    display: inline;
}

.not-found-error {
    justify-content: center;
    align-items: center;
}

.not-found-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.not-found-container img {
    width: 50%;
}

.not-found-container h2 {
    color: white;
}

.loading-container {
    height: 50%;
    width: 50%;
    position: absolute;
}

.settings-cog-container {
    position: absolute;
    height: 50px;
    width: 60px;
    border-radius: 8px;
    background-color: var(--opal);
    cursor: pointer;

    bottom: 30px;
    right: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-transition: 0.12s ease-in-out;
    -moz-transition: 0.12s ease-in-out;
    -o-transition: 0.12s ease-in-out;
    transition: 0.12s ease-in-out;
}

.settings-cog-container:hover {
    transform: scale(1.1, 1.1);
    background-color: var(--bright-green);
}

.box-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.box-container > .box:not(:last-child) {
    margin-right: 5rem;
}

.box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--bright-green);
    width: 17rem;
    height: 17rem;
    user-select: none;

    -webkit-transition: 0.12s ease-in-out;
    -moz-transition: 0.12s ease-in-out;
    -o-transition: 0.12s ease-in-out;
    transition: 0.12s ease-in-out;
}

.box:hover { transform: scale(1.05); }

.box > h5 {
    color: #102C2E;
    margin-top: 2rem;
    margin-bottom: 0rem;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

.box-img {
    width: 120px;
    height: 120px;
}