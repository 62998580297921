.profile-header {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;
    flex: 1;
}

.profile-image {
    padding: 0rem;
    border-radius: 50% !important;
}

.profile-name {
    color: #000000;
    margin: 1rem 0 0 0;
}

.profile-email {
    color: #000000;
    margin: 0.5rem 0 0 0;
}

.bottom-button-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.call-settings {
    flex: 1;
    padding: 1.5rem;
}

.mute-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.audio-state-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
}

.audio-state-view > button {
    border: none;
    background-color: rgba(0, 0, 0, 0.2);
    margin-right: 0.5rem;
}

.audio-state-view > .active {
    background-color: #000000;
    color: white;
}