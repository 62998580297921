.toolbar-bg {
    background-color: #000000;
    width: 100%;
    display: flex;
    flex-flow: row;
    grid-row-start: auto;
    grid-row-end: auto;
    z-index: 200;
}

.toolbar-call-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.toolbar-call-btn {
    border-radius: 10px;
    cursor: pointer;
    height: 80%;
    width: 80%;
    padding: 0.2rem 0rem;
    position: relative;

    -webkit-transition: 0.15s ease-in-out;
    -moz-transition: 0.15s ease-in-out;
    -o-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
}

.toolbar-call-btn:hover {
    background-color: var(--dark-green);
}

.toolbar-call-btn-inner {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
}

.toolbar-call-btn-inner > p {
    margin: 3px 0 0 0;
    color: white;
    font-size: 12px;
    user-select: none;
}

.toolbar-call-btn-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.toolbar-call-btn-icon {
    color: white;
    width: 28px;
}

.toolbar-call-btn-chevron {
    position: absolute;
    color: white;
    border-radius: 5px;
    top: 0px;
    right: 0px;
}

.toolbar-call-btn-chevron:hover {
    background-color: rgba(18, 14, 37, 0.4);
}

.red-bg {
    background: var(--red-dit);
    color: var(--betty-white);
}

.toolbar-grid {
    display: grid;
    gap: 0rem;
    /* margin-top: 15px; */
    /* margin-bottom: 15px; */
    height: 100%;
}

.toolbar-grid-start {
    grid-template-columns: 2.5rem 2.5rem;
    justify-content: flex-start;
}

.toolbar-grid-center {
    grid-template-columns: 7rem 7rem 7rem 7rem 7rem 7rem;
    justify-content: center;
}

.toolbar-grid-center-before {
    grid-template-columns: 7rem 7rem 7rem;
    justify-content: center;
}

.toolbar-grid-end {
    grid-template-columns: 1fr;
    justify-content: flex-end;
}

.toolbar-grid-end-extra {
    grid-template-columns: 2.5rem 2.5rem;
    justify-content: flex-end;
}

.toolbar-grid > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}

.notification-icon {
    width: 15px;
    height: 15px;
    background-color: var(--red-dit);
    border-radius: 50%;

    position: absolute;
    top: 0%;
    right: 30%;
}

.notification-icon-hidden {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: transparent;
}

.copy-hover {
    position: absolute;
    top: -80px;
    background-color: #040808;
    width: 100px;
    border-radius: 0.5rem;
    user-select: none;
}

.copy-hover > p {
    text-align: center;
    margin: 0;
    padding: 0.7rem 0.5rem 0.5rem 0.5rem;
    color: white;
}

.invite-btn > img {
    width: 25px;
}