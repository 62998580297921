.person-md {
    height: 35px;
    width: 35px;
    border-radius: 0.25rem;
    background-color: #7f8fa6;
}

.person-text-md {
    font-size: 17px;
    font-weight: 600;
}

.chat-header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 0.1px solid gray;
}

.chat-header > div > h4 {
    font-weight: 500;
    padding-top: 0.3rem;
}

.chat-scroll-container {
    flex: 1;
    /* display: flex; */
    flex-direction: column;
    overflow: scroll;
    /* justify-content: end; */
}

.chat-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleted-chat-text {
    font-weight: 500;
    font-size: 15px;
    font-style: italic;
}

.date-header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-header-container small {
    flex: 2;
    text-align: center;
    margin: 0;
    font-weight: bold;
}

.date-header-container hr {
    background-color: gray;
}

.chat-trash-button {
    margin-left: 0.5rem;
    width: 28px;
    cursor: pointer;
}

.chat-close-button {
    cursor: pointer;
    width: 38px;
}

.message-box {
    margin-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
    display: flex;
    flex-flow: row;
    position: relative;
}

.message-box:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.message-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: 0.75rem;
}

.message-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    font-size: 0.9rem;
}

.message-form {
    width: 100%;
    display: flex;
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: 1rem;
    background-color: var(--opal);
}

.new-message {
    width: 100%;
    height: 100%;
    border: 0px;
    /* padding: 1rem; */
    background: transparent;
    color: #000000;
}

.new-message::placeholder {
    color: #000000;
}

.new-message:focus {
    outline: none;
}

.message-form-buttons {
    justify-content: flex-end;
    border: 0;
    background-color: transparent;
}

.send { cursor: pointer; }

.messages-feed {
    width: 100%;
    overflow-y: scroll;
    flex: 1;
    /* background-color: var(--primary-accent); */
}
